import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import imageIcon from './sign.svg';

export default class InsertSignaturePlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add( 'signatureDropdown', locale => {
      const dropdownView = createDropdown( locale );

      dropdownView.buttonView.set({
        label: '签名',
        icon: imageIcon,
        tooltip: true,
      });

      const items = new Collection();

      const setSignatureModel = new Model({
        label: '设置签名',
        withText: true,
      });
      setSignatureModel.set({
        value: 'set',
      });
      items.add({
        type: 'button',
        model: setSignatureModel,
      });
      items.add({
        type: 'seperator',
      });

      const insertSignatureModel = new Model({
        label: '插入签名',
        withText: true,
      });
      insertSignatureModel.set({
        value: 'insert',
      });
      items.add({
        type: 'button',
        model: insertSignatureModel
      });
      addListToDropdown( dropdownView, items );

      this.listenTo( dropdownView, 'execute', e => {
        this.editor.fire(`${e.source.value}Signature`);
      });

      return dropdownView;
    } );
  }
}