import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from './attachment.svg';

export default class InsertAttachmentPlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add( 'insertAttachment', locale => {
        const view = new ButtonView( locale );

        view.set( {
            label: '上传附件',
            icon: imageIcon,
            tooltip: true
        } );

        view.on( 'execute', () => {
          this.editor.fire(`insertAttachment`);
        } );

        return view;
    } );
  }
}